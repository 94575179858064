import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageDomeInvalideQuery {
        file(relativePath: { eq: "invalides.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Cabinet du chirurgie orthopédique situé à Paris 7 à proximité des Invalides"
        css={css`
          width: 68px;
          float: left;
          margin-right: 20px;
        `}
      />
    )}
  />
)
