import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageLogoDomeInvalideQuery {
        file(
          relativePath: {
            eq: "cabinet-chirurgie-orthopedique-paris-invalides.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Logo du Dr Roure, chirurgien de main, du coude et de l'épaule à Paris 7"
        css={css`
          width: 48px;
          filter: brightness(180%);
          float: left;
          margin-right: 5px;
          @media (max-width: 678px) {
            display: none;
          }
        `}
      />
    )}
  />
)
