import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const moreSubMenu = () => (
  <Wrapper className="submenu">
    <Ul>
      <Li>
        <StyledLink to="/videos/" activeClassName="active">
          Vidéos & Émissions TV
        </StyledLink>
      </Li>
      <Li>
        <StyledLink to="/nos-patients-l-ont-fait/" activeClassName="active">
          Nos Patients l'ont fait
        </StyledLink>
      </Li>
      <Li>
        <StyledLink to="/temoignages/" activeClassName="active">
          Témoignages
        </StyledLink>
      </Li>
      <Li>
        <StyledLink to="/actus/" activeClassName="active">
          Actualités
        </StyledLink>
      </Li>
    </Ul>
  </Wrapper>
)

const Wrapper = styled.div`
  position: absolute;
  top: 32px;
  right: -10px;
  background-color: white;
  width: 205px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  box-shadow: rgba(0, 0, 0, 0.15) 12px 12px 10px -10px;
  @media (max-width: 1050px) {
    position: relative;
    top: 0;
    right: 0;
    border: none;
    box-shadow: none;
  }
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0.3em 0 0.3em;
  @media (max-width: 1050px) {
    margin-top: -12px;
  }
`

const Li = styled.li`
  font-size: 1em;
  line-height: 1.55em;
  padding: 0.4em 0.8em 0.3em;
  letter-spacing: 0.01em;
  a {
    text-decoration: none;
    transition: all 0.15s ease-in-out;
  }
  :hover {
    a {
      color: #ca9d63;
      transition: all 0.15s ease-in-out;
    }
  }
  @media (max-width: 1050px) {
    padding: 0.5em 0 0.5em;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  :hover,
  :focus {
    color: #d6af68;
  }
`

export default moreSubMenu
