import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import {
  FaCalendarCheck,
  FaPhone,
  FaExternalLinkAlt,
  FaInfoCircle,
} from "react-icons/fa"

const PopUpRdv = () => (
  <Wrapper>
    <Col1>
      <Link
        to="/contact/#rdv"
        css={css`
          text-decoration: none;
          color: white;
        `}
      >
        <FaCalendarCheck
          css={css`
            display: block;
            font-size: 2em;
            margin: 0 auto 10px;
            transform: translateY(1px);
          `}
        />
        Prendre
        <br />
        rendez-vous
      </Link>
    </Col1>

    <Col2>
      <div
        css={css`
          @media (max-width: 678px) {
            display: none;
          }
        `}
      >
        Vous pouvez prendre RDV pour une consultation&nbsp;:
      </div>
      <Ul>
        <Li>
          <A href="tel:+33153598809" target="_blank" rel="noreferrer noopener">
            <FaPhone
              css={css`
                transform: translateY(1px);
              `}
            />
            &nbsp;01 53 59 88 09 (Espace Médical Vauban)
          </A>
        </Li>
        <Li>
          <A href="tel:+33186867505" target="_blank" rel="noreferrer noopener">
            <FaPhone
              css={css`
                transform: translateY(1px);
              `}
            />
            &nbsp;01 86 86 75 05 (Clinique du Sport)
          </A>
        </Li>
        <Li>
          <A
            href="https://www.doctolib.fr/chirurgien-orthopediste/paris/philippe-roure"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaExternalLinkAlt
              css={css`
                transform: translateY(1px);
              `}
            />
            &nbsp;Prendre RDV via Doctolib (au cabinet ou à distance)
          </A>
        </Li>
        <Li>
          <FaInfoCircle
            css={css`
              transform: translateY(1px);
            `}
          />
          &nbsp;Seule une partie de l'agenda est accessible sur Doctolib
        </Li>
      </Ul>
    </Col2>
  </Wrapper>
)

export default PopUpRdv

// bottom used to be
// bottom: 8vh;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  bottom: 14vh;
  right: -410px;
  z-index: 4;
  width: 510px;
  border-radius: 7px 0 0 7px;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  box-shadow: rgba(0, 0, 0, 0.15) 12px 12px 10px -10px;
  color: white;
  transition: all;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  transition: all 0.2s ease-in-out;
  :hover {
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 1200px) {
    top: 71vh;
  }
  @media (max-width: 678px) {
    height: 90px;
    :hover {
      right: -413px;
    }
  }
`

const Col1 = styled.div`
  width: 90px;
  background-color: rgba(44, 44, 44, 0.98);
  padding: 35px 10px 25px;
  border-radius: 7px 0 0 7px;
  @media (max-width: 1024px) {
    width: 85px;
    padding: 10px 8px 15px;
  }
`

const Col2 = styled.div`
  width: 410px;
  background-color: white;
  color: rgba(44, 44, 44, 0.98);
  padding: 10px 10px 0px;
  text-align: left;
  @media (max-width: 678px) {
    padding: 5px 5px 0px;
    width: 100vw;
  }
`

const Ul = styled.ul`
  padding: 0 0 0 8px;
  font-weight: 400;
  margin: 5px 0 10px;
`

const Li = styled.li`
  list-style-type: none;
  padding-bottom: 5px;
`

const A = styled.a`
  text-decoration: none;
  transition: all 0.3s;
  padding: 2px 0 5px;
  :hover {
    background-color: hsla(193, 5%, 62%, 0.2);
    border-color: hsla(193, 5%, 62%, 0.2);
    transition: all 0.3s;
  }
`
