import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const OperationsSubMenu = () => (
  <Wrapper className="operation-submenu">
    <FlexWrapper>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/chirurgie-coude/">
            Chirurgie du coude
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-coude/compression-nerf-cubital/"
            >
              Compression du nerf cubital{" "}
              <span class="hidden-sm">(ou nerf ulnaire)</span>
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-coude/epicondylite-tendinite-coude/"
            >
              Epicondylite&nbsp;: Tendinite du coude
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-coude/epitrochleite-tendinite-mediale/"
            >
              Épitrochléite&nbsp;: Tendinite médiale du coude
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-coude/rupture-biceps/"
            >
              Rupture du biceps
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/chirurgie-epaule/">
            Chirurgie de l'épaule
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/arthrose-acromio-claviculaire/"
            >
              Arthrose acromio-claviculaire
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/conflit-sous-acromial/"
            >
              Conflit sous-acromial
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/fracture-clavicule/"
            >
              Fracture de la clavicule
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/luxation-acromio-claviculaire-luxation-de-la-clavicule/"
            >
              Luxation acromio-claviculaire / Luxation de la clavicule
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/luxation-epaule/"
            >
              Luxation de l'épaule
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/omarthrose-arthrose-epaule/"
            >
              Omarthrose
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/operation-bankart/"
            >
              Opération de Bankart
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/operation-latarjet/"
            >
              Opération de Latarjet
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/prothese-epaule/"
            >
              Prothèse de l’épaule
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/rupture-coiffe-des-rotateurs/"
            >
              Rupture de la coiffe des rotateurs
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-epaule/tendinopathie-coiffe-rotateurs/"
            >
              Tendinopathie de la coiffe des rotateurs
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/chirurgie-poignet/">
            Chirurgie du poignet
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/arthroscopie-poignet/"
            >
              Arthroscopie du poignet
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/entorse-poignet/"
            >
              Entorse du poignet
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/fracture-du-poignet/"
            >
              Fracture du poignet
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/fracture-scaphoide/"
            >
              Fracture du scaphoïde
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/kyste-synovial-poignet/"
            >
              Kyste synovial du poignet
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/maladie-de-kienbock/"
            >
              Maladie de Kienböck
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/pseudarthrose-du-scaphoide/"
            >
              Pseudarthrose du scaphoïde
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-poignet/tendinite-poignet/"
            >
              Tendinite de De Quervain
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/videos/conseils-apres-chirurgie-poignet/"
            >
              Conseils après chirurgie du poignet
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/chirurgie-main/">
            Chirurgie de la main
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-main/arthrose-des-doigts/"
            >
              Arthrose des doigts
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/chirurgie-main/doigt-ressaut/">
              Doigt à ressaut
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/chirurgie-main/entorse-pouce/">
              Entorse du pouce
            </Link>
          </Li>

          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-main/maladie-dupuytren/"
            >
              Maladie de Dupuytren
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-main/kyste-mucoide-doigt/"
            >
              Kyste mucoïde du doigt
            </Link>
          </Li>

          <Li>
            <Link activeClassName="active" to="/chirurgie-main/plaie-main/">
              Plaie de la main
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/chirurgie-main/rhizarthrose/">
              Rhizarthrose : Arthrose du pouce
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/chirurgie-nerfs/syndrome-canal-carpien/"
            >
              Syndrome du canal carpien
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/chirurgie-main/urgence-main/">
              Urgence de la main
            </Link>
          </Li>
        </Ul>
      </FlexItem>
      <FlexItem>
        <Cat>
          <Link activeClassName="active" to="/techniques/">
            Techniques pratiquées
          </Link>
        </Cat>
        <Ul>
          <Li>
            <Link activeClassName="active" to="/techniques/arthroscopie/">
              Arthroscopie
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/techniques/facteurs-de-croissance/"
            >
              Facteurs de croissance ou PRP
            </Link>
          </Li>
          <Li>
            <Link activeClassName="active" to="/techniques/microchirurgie/">
              Microchirurgie
            </Link>
          </Li>
          <Li>
            <Link
              activeClassName="active"
              to="/techniques/ortheses-thermoformables/"
            >
              Orthèses thermoformables
            </Link>
          </Li>
        </Ul>
        <Cat
          css={css`
            padding-top: 0.1em;
          `}
        >
          <Link activeClassName="active" to="/chirurgie-du-sport/">
            Chirurgie du sport
          </Link>
        </Cat>
        <Cat
          css={css`
            padding-top: 0.3em;
          `}
        >
          <Link activeClassName="active" to="/chirurgie-nerfs/">
            Chirurgie des nerfs
          </Link>
        </Cat>
      </FlexItem>
    </FlexWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  position: absolute;
  top: 84px;
  left: 0;
  padding: 0 10px;
  background-color: white;
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  box-shadow: rgba(0, 0, 0, 0.15) 12px 12px 10px -10px;
  @media (max-width: 1050px) {
    z-index: 3;
    position: fixed;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 15px;
  z-index: 30;
  @media (max-width: 1050px) {
    display: grid;
    padding: 0px 12px 15px;
    box-sizing: border-box;
  }
  @media (max-width: 678px) {
    padding-left: 0;
  }
`

const FlexItem = styled.div`
  width: 20%;
  display: inline-block;
  displey: flex;
  flex-direction: column;
  padding: 0 8px;
  :first-of-type {
    padding-left: 0px;
  }
  :last-of-type {
    padding-right: 0px;
  }
  @media (max-width: 1050px) {
    width: 100%;
    padding: 0;
  }
`

const Cat = styled.div`
  font-size: 1.14em;
  letter-spacing: 0.025em;
  font-weight: 600;
  width: 100%;
  padding-bottom: 0.5em;
  font-family: Raleway, "Times new roman", serif;
  a {
    text-decoration: none;
  }
  :hover {
    a {
      color: #ca9d63;
    }
  }
  @media (max-width: 1050px) {
    margin: 10px 0 2px;
    padding-bottom: 0;
  }
  @media (max-width: 678px) {
    margin: 6px 0 0;
  }
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media (max-width: 1050px) {
    display: inline;
  }
  @media (max-width: 678px) {
    line-height: 150% !important;
  }
`

const Li = styled.li`
  font-size: 0.9em;
  line-height: 1.55em;
  padding-bottom: 0.6em;
  letter-spacing: 0.01em;
  a {
    text-decoration: none;
    padding: 0.25em 0;
    display: block;
    transition: all 0.15s ease-in-out;
  }
  :hover {
    a {
      color: #ca9d63;
      transition: all 0.15s ease-in-out;
    }
  }
  @media (max-width: 1050px) {
    display: inline;
    a {
      display: inline;
      ::after {
        content: "•";
        margin: 0 7px;
        color: #333;
      }
    }
    :last-of-type {
      a {
        ::after {
          content: "";
        }
      }
    }
  }
  @media (max-width: 678px) {
    font-size: 0.84em;
    padding-bottom: 0.2em;
  }
`

export default OperationsSubMenu
